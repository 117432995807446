import React, { useEffect, useState } from "react";
import Navigation from "../components/navigation"; // Import der PhotoBox-Komponente
import PhotoBox from "../components/photobox"; // Import der PhotoBox-Komponente
import Products from '../components/products';
import Legal from '../components/legal';

const Home = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const progress = Math.min(scrollY / windowHeight, 1);
      setScrollPosition(progress);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main id="home" className="site site--home">
      <Navigation />

      <section className="stage">
        <span className="stage--gradient"></span>
        <div className="stage--content">
          <span
            className="first-line"
            style={{
              transform: `translate3d(${scrollPosition * -300}px, 0, 0)`,
            }}
          >
            Jetzt Tickets
          </span>

          <span
            className="second-line"
            style={{
              transform: `translate3d(${scrollPosition * 300}px, 0, 0)`,
            }}
          >
            gewinnen!
          </span>
        </div>
        {/* Einbindung der PhotoBox-Komponente */}
        <PhotoBox />

      </section>

      {/* Produkte Teaser (mit Modal zur Anzeige der Preise) */}
      <Products />

      {/* Legal Text */}
      <Legal />
    
    </main>
  );
};

export default Home;
