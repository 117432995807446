import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";

const PhotoBox = () => {
  const webcamRef = useRef(null);
  const [image, setImage] = useState(null);
  const [email, setEmail] = useState(""); // E-Mail-Adresse
  const [optIn, setOptIn] = useState(false); // Opt-In für Newsletter
  const [emailValid, setEmailValid] = useState(false); // Validierung der E-Mail
  const [uploadStatus, setUploadStatus] = useState(""); // Statusmeldung
  const navigate = useNavigate();

  const videoConstraints = {
    width: 1080,
    height: 1080,
    facingMode: "user",
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setEmailValid(validateEmail(inputEmail));
  };

  const handleOptInChange = (e) => {
    setOptIn(e.target.checked);
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot({ width: 1080, height: 1080 });
    setImage(imageSrc);
  };

  const uploadPhoto = async () => {
    if (!image || !emailValid || !optIn) return;

    const base64Data = image.split(",")[1];
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(null)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    const formData = new FormData();
    formData.append("image", blob, "webcam-photo.jpg");
    formData.append("email", email); // E-Mail hinzufügen
    formData.append("opt_in", optIn ? "1" : "0"); // Opt-In für Newsletter

    try {
      const response = await fetch(
        "https://photobox.if.digital/functions/upload.php",
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();

      if (result.success) {
        setUploadStatus(
          <p className="message">Upload erfolgreich!</p>
        );
        navigate("/confirmation");
      } else {
        setUploadStatus(
          <p className="message">Upload fehlgeschlagen: {result.error}</p>
        );
      }
    } catch (error) {
      setUploadStatus(
        <p className="message">Fehler: {error.message}</p>
      );
    }
  };

  return (
    <div className="photobox">
      {!image ? (
        <div className="photobox--camera-container">
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            className="photobox--webcam"
          />
          <button onClick={capturePhoto} className="button takephoto-button">
            Foto<br/>aufnehmen
          </button>
        </div>
      ) : (
        <div className="photobox--camera-container">
          <img src={image} alt="Aufgenommenes Bild" className="photobox--webcam" />

          {!emailValid && email && (
            <p className="message">
              Gib deine Mail-Adresse korrekt ein.
            </p>
          )}

          <nav className="photobox--nav">
            <div className="photobox--nav-email-container">
              <div className="inputControl">
                <i className="inputIcon">
                  <img src="../images/icon--mail.svg" alt="E-Mail Icon" />
                </i>
                <label htmlFor="email">E-Mail Adresse:</label>
                <input
                  type="email"
                  placeholder="Gib deine E-Mail ein"
                  value={email}
                  onChange={handleEmailChange}
                  className="email-input"
                  required
                />
              </div>
              <br />
              <div className="checkboxControl">
                <input
                  type="checkbox"
                  id="opt_in"
                  checked={optIn}
                  onChange={handleOptInChange}
                  required
                />
                <label htmlFor="opt_in">
                  Mit der Eingabe deiner E-Mail-Adresse erklärst du dich damit einverstanden, unseren Newsletter zu erhalten und stimmst der Verwendung deiner Daten zur Abwicklung des Gewinnspiels zu. Du kannst dich jederzeit abmelden.
                </label>
              </div>
            </div>

            <button
              onClick={() => setImage(null)}
              className="photobox--nav-button retake-button"
            >
              Neues<br/>Foto
            </button>

            <button
              onClick={uploadPhoto}
              className="photobox--nav-button upload-button"
              disabled={!emailValid || !optIn}
            >
              Foto<br/>hochladen
            </button>
          </nav>
        </div>
      )}
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
};

export default PhotoBox;