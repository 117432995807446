import React from "react";

const Legal = () => {
  return (
    <section className="legal" id="legal">
      <h2>Terms and Conditions</h2>

      <div className="legal--inner">
        <div>
          <h3>§1 Participation</h3>
          <p>
            (1) Eligible participants are persons of legal age. Minors require
            the written consent of their legal guardian to participate.
          </p>
          <h3>§ 2 Exclusion from the Competition</h3>
          <p>
            (1) Employees of Semmel Concerts, the involved cooperation partners,
            as well as their relatives, are excluded from participation.
          </p>
          <p>
            (2) In the event of a violation of these terms and conditions of
            participation, Semmel Concerts reserves the right to exclude
            individuals from the competition.
          </p>
          <p>
            (3) Individuals who use unauthorized aids or gain advantages through
            manipulation will also be excluded.
          </p>
          <h3>§ 3 Execution and Handling</h3>
          <p>
            (1) The winners will be notified by Semmel Concerts via email and
            may be publicly named in Semmel Concerts publications. The winner
            explicitly agrees to this form of publication.
          </p>
          <p>
            (2) If the winner does not respond within one week of the
            notification being sent, the claim to the prize will expire, and a
            new winner will be drawn. The claim to the prize also expires if the
            delivery of the prize cannot be made within 3 months after the first
            notification for reasons attributable to the winner.
          </p>
          <p>
            (3) The prizes will be shipped via UPS to the postal address
            provided by the winner. Delivery within the Federal Republic of
            Germany is free of charge. Any additional transport costs and
            customs duties must be borne by the winner.
          </p>
          <p>
            (4) A cash payout of the prizes or any prize replacement is not
            possible under any circumstances.
          </p>
          <p>
            (5) The claim to the prize or prize replacement cannot be assigned.
          </p>
          <p>
            (6) Complaints regarding the execution of the competition must be
            submitted in writing to Semmel Concerts, specifying the respective
            competition, within 14 days of the reason for the complaint becoming
            known. Complaints made by phone or submitted late will not be
            processed.
          </p>
        </div>
        <div>
          <h3>§ 4 Early Termination of the Competition</h3>
          <p>
            Semmel Concerts reserves the right to terminate the competition at
            any time without prior notice and without giving reasons. Semmel
            Concerts will especially exercise this right if technical reasons
            (e.g., viruses in the computer system, manipulation, or hardware
            and/or software errors) or legal reasons prevent the proper
            execution of the game. If such termination is caused by the behavior
            of a participant, Semmel Concerts may claim damages from this
            person.
          </p>
          <p>&nbsp;</p>
          <h3>§ 5 Data Protection</h3>
          <p>
            In order to participate in a competition, registration is
            necessary. There is no entitlement to registration. By registering,
            the participant explicitly agrees that Semmel Concerts will store
            the necessary data for the duration of the competition and transmit
            it to the respective cooperation partner for the purpose of
            processing the prize, who may also store the personal data for the
            duration of the competition. Furthermore, the participant agrees
            that their personal data will be collected, processed, and used by
            Semmel Concerts Veranstaltungsservice GmbH for customer support
            purposes and for the individual creation and sending of selected
            product information.
          </p>
          <p>
            The participant is free to revoke consent to the storage of their
            data at any time and thus withdraw from participation.
          </p>
          <p>&nbsp;</p>
          <h3>§ 6 Miscellaneous</h3>
          <p>(1) The right to legal action is excluded.</p>
          <p>
            (2) The law of the Federal Republic of Germany is exclusively
            applicable.
          </p>
          <p>
            (3) Should individual provisions of these terms and conditions be or
            become invalid, the validity of the remaining terms and conditions
            shall remain unaffected.
          </p>
          <p>
            (4) These terms and conditions may be changed at any time by Semmel
            Concerts without separate notification.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Legal;