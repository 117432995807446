import React, { useState, useEffect } from "react";
import Navigation from "../components/navigation";
import Legal from "../components/legal";
import Confetti from "react-confetti";

const Success = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showConfetti, setShowConfetti] = useState(true);
  const [confettiPieces, setConfettiPieces] = useState(2000); // Anzahl der Konfetti-Teilchen

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const progress = Math.min(scrollY / windowHeight, 1);
      setScrollPosition(progress);
    };

    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Timer, um sofort mit der Reduzierung der Konfetti-Teilchen zu beginnen
    const interval = setInterval(() => {
      setConfettiPieces((prev) => {
        if (prev <= 0) {
          clearInterval(interval);
          setShowConfetti(false);
          return 0;
        }
        return prev - 10; // Reduziert die Anzahl der Konfetti-Teilchen allmählich
      });
    }, 100);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
      clearInterval(interval);
    };
  }, [scrollPosition]);

  return (
    <main id="success" className="site site--susccess">
      {/* Navigation */}
      <Navigation />

      {/* Konfetti-Regen */}
      {showConfetti && (
        <Confetti
          numberOfPieces={confettiPieces}
          width={windowDimensions.width}
          height={windowDimensions.height}
        />
      )}

      <section className="stage">
        <span className="stage--gradient"></span>
        <div className="checkmark-container">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <div className="lead">
          <h2>Herzlichen Glückwunsch!</h2>
          <br />
          <p>
            Deine Teilnahme am Gewinnspiel war erfolgreich. Wir haben deine
            E-Mail erhalten und du bist nun im Lostopf.
          </p>
          <br />
          <p>
            Bitte überprüfe regelmäßig deinen E-Mail-Posteingang, um zu sehen,
            ob du gewonnen hast. Viel Glück!
          </p>
          <br />
          <a
            className="button-control"
            href="https://www.musical-grease.de/"
            title="Zur offiziellen Website"
          >
            Zur offiziellen Website
          </a>
        </div>
      </section>

      {/* Legal Text */}
      <Legal />
    </main>
  );
};

export default Success;