import React from "react";

const Navigation = () => {
  return (
    <nav className="navigation">
      <div className="navigation--cta d-mobile-none">
        <a
          className="buttonControl buttonControl--outlined"
          href="https://www.musical-grease.de/"
          target="_blank"
          title="Zurück zur Startseite"
          rel="noreferrer"
        >
          Offizielle Website
        </a>
      </div>
      <a className="navigation--logo" href="/" title="Start">
        <img
          width={"200px"}
          src="../images/logo.png"
          alt="Grease – Das Original Hit Musical aus London"
        />
      </a>
      <ul className="navigation--bar">
        <li>
          <a href="#win">Gewinnspiel</a>
        </li>
        <li>
          <a href="#legal">Teilnahmebedingungen</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
