import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home"; // Import der Home-Seite
import Confirmation from "./pages/confirmation"; // Import der Confirmation-Seite
import Success from "./pages/success"; // Import der Success-Seite
import './styles/styles.scss'; // Globale SCSS-Datei importieren

const App = () => {
  return (
    <Router>
      <div>
        {/* Routing-Konfiguration */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;