import React, { useState, useEffect } from "react";
import Navigation from "../components/navigation";
import Legal from "../components/legal";

const Confirmation = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const progress = Math.min(scrollY / windowHeight, 1);
      setScrollPosition(progress);
    };

    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [scrollPosition]);

  return (
    <main id="confirmation" className="site site--home">
      {/* Navigation */}
      <Navigation />

      <section className="stage">
        <span className="stage--gradient"></span>
        <div className="checkmark-container">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <div className="lead">
          <h2>Prüfe jetzt deinen E-Mail-Postfacheingang,</h2>
          <br />
          <p>
            um deine Teilnahme am Gewinnspiel zu bestätigen. Bitte überprüfe
            auch deinen Spam-Ordner, falls du die E-Mail nicht in deinem
            Posteingang findest.
          </p>
          <br />{" "}
          <a
            className="button-control"
            href="https://www.musical-grease.de/"
            title="Zur offiziellen Website"
          >
            Zur offiziellen Website
          </a>
        </div>
      </section>

      {/* Legal Text */}
      <Legal />
    </main>
  );
};

export default Confirmation;
